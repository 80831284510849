/* landingpage.module.css */

.root {
    font-family: Arial, sans-serif;
    text-align: center;
    background-color: #f2f2f2;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    text-align: center;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin-bottom: 30px;
}

.logo {
    max-width: 150px;
    margin-bottom: 15px;
}

h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

p {
    font-size: 1.2em;
    color: #666;
    line-height: 1.5;
}

.installBtn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #4CAF50;
    color: white;
    font-size: 1.2em;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 30px;
}

.installBtnHover:hover {
    background-color: #45a049;
}

.footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.footerLink {
    color: #666;
    text-decoration: none;
    font-size: 0.9em;
    margin: 0 10px;
}

.footerLinkHover:hover {
    color: #333;
}

.disclaimer {
    color: #999;
    margin-top: 20px;
    font-size: 0.8em;
}

@media (max-width: 768px) {
    .root {
        padding: 20px;
    }

    .footer {
        flex-direction: column;
    }

    .footerLink {
        margin: 10px 0;
    }
}
