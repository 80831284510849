body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #f9f9f9;
}



.searchBar {
    /* Remove padding and integrate the search bar into the navbar */
    padding: 0;
}

/* Add a new class for the red button */
.redButton {
    background-color: #ff0000; /* Red background */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 20px;
    border-radius: 4px; /* Adjust as needed to match the button curvature in the image */
    font-weight: bold; /* Make the text bold */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Add hover effect for the red button */
.redButton:hover {
    background-color: #e60000; /* Slightly darker red on hover */
}

/* Style the search input within the navbar */
.navbar .searchInput {
    /* Add styles to match the search bar in the image */
    border: 1px solid #ccc; /* Add border */
    border-radius: 4px; /* Add border-radius */
    padding: 10px; /* Add padding */
}


.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #ffffff; /* Change to white or the color in the screenshot */
    border-bottom: 1px solid #ccc; /* Add a bottom border if there's one in the image */
}

.navbar .logo {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
}

h1 {
    color: #400090;
    text-align: center;
    padding: 20px 0;
}

.MuiCard-root {
    margin: 15px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 1px 6px rgba(0,0,0,.12), 0 1px 4px rgba(0,0,0,.12);

}

/* Add transitions to the search input for smooth animation */
.searchInput {
    transition: width 0.5s ease-in-out;
    width: 100px; /* Default smaller width */
    /* other styles */
}

/* Class to expand the width of the search input */
.expandedSearchInput {
    width: 300px; /* Expanded width */
}


.adContainer {
    display: flex;
    align-items: baseline; /* Vertically aligns children in the middle */
    font-size: 0.875rem; /* Sets the font size for the container */
}

.adLabel {
    background-color: darkgray; /* Green background for the 'Ad' label */
    border-radius: 2px; /* Rounded corners for the label */
    color: white; /* White text for the label */
    font-size: 0.75rem; /* Smaller font size for the label */
    padding: 2px 4px; /* Padding around the label */
    margin-right: 8px; /* Space between label and URL */
    font-weight: bold; /* Bold font weight for the label */
}

.adTag {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
    padding: 5px;
    background-color: #f1f1f1;
    border-left: 3px solid #000;
}

.mainLabel {
    margin-left: 15px;
}

.siteHost {
    color: purple; /* Purple text for the URL */
    margin: 0; /* Reset default margins */
}

.MuiTypography-h5 a {
    color: inherit; /* Inherit the text color from the h2 */
    text-decoration: none; /* Remove underline if desired */
}

.MuiTypography-h5 a:hover {
    text-decoration: underline; /* Optional: add underline on hover */
}


.MuiCard-root:hover h2 a {
    cursor: pointer;
}

.siteHost {
    margin-top: -10px; /* adjust this value as needed */
}

.MuiTypography-root {
    color: #400090;
}

.MuiTypography-h5 {
    margin-bottom: 10px;
}

.MuiLink-root {
    color: #0078d7;
    text-decoration: none;
}

.MuiTypography-body2 {
    color: #4d4d4d;
    line-height: 1.6;
}

.searchBar {
    padding: 20px;
}



.searchInput {
    font-size: 20px;
    padding: 10px;
    flex-grow: 1;
}

.searchButton {
    margin-left: 10px;
    background-color: #7b0099;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px 20px;
}

.searchInput,
.searchButton {
    height: 35px;
    box-sizing: border-box;
}

