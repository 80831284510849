.ppcbutton {
    display: inline-block; /* Make the button inline-block */
    position: relative; /* Ensure positioning for the pseudo-element */
    width: 100%; /* Ensure the button takes full width */
    overflow: hidden; /* Hide overflow for the triangle */
}

.ppcbutton a {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    padding: 0.6rem 2rem; /* Adjust padding */
    font-size: 1.2rem;
    background-color: #09246a; /* Background color */
    text-decoration: none; /* No underline */
    display: inline-flex; /* Inline flex to fit content */
    justify-content: center; /* Center the content */
    align-items: center; /* Center the content */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    position: relative; /* Ensure positioning for the pseudo-element */
    width: 100%; /* Ensure the button takes full width */
    box-sizing: border-box; /* Include padding in width calculation */
}

.ppcbutton a::after {
    content: "";
    position: absolute;
    top: 0;
    right: -1.2rem; /* Move the triangle to the end of the button */
    width: 0;
    height: 100%; /* Ensure the triangle spans the height of the button */
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.2rem solid #09246a; /* Triangle color and width */
}

.ppcbutton a:hover::after {
    border-left-color: #061a4d; /* Darker shade for hover effect */
}

.ppcbutton a span::after {
    content: " >>>"; /* Optional text arrow */
    font-size: 1.6rem;
    margin-left: 0.5rem;
}